<template>
  <el-table
    ref="multipleTable"
    :data="tableData"
    tooltip-effect="dark"
    style="width: 100%"
    height="500"
    v-loading="loading"
  >
    <el-table-column label="关键词" prop="query" width="200"></el-table-column>
    <el-table-column label="翻译" prop="cnTitle" width="150"></el-table-column>
    <el-table-column prop="indexed_queries" label="热搜指数"> </el-table-column>
    <el-table-column label="FB兴趣词">
      <template v-slot="{ row }">
        <el-link
          :underline="false"
          @click.stop="tranlateRow(row, $event)"
          type="primary"
        >
          点击查看
        </el-link>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    tranlateRow(row, e) {
      e.stopPropagation();
      this.$emit("view-targeting", {title:row.query});
    },
  },
};
</script>