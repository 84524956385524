<template>
    <el-drawer
        title="推荐广告语"
        :visible.sync="drawer"
        :append-to-body="true"
        :modal-append-to-body="false"
        size="30%">
        <div class="sale-box" v-loading="loading">
            <el-form>
                <el-form-item>
                    <div>
                        <el-dropdown v-for="item in tagInfo" :key="item.key" trigger="click" @command="commandHandle">
                            <el-button type="primary" size="mini" plain>
                                {{ (item.key).substring(3) }}<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="_item in item.values" :command="_item" :key="_item"><p>{{ _item }}</p></el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </el-form-item>
                <!-- <el-form-item label="品类：" label-width="60px">
                    <span style="color: #999">{{ formData.categoryFullName }}</span>
                </el-form-item>
                <el-form-item label="名称：" label-width="60px">
                    <span style="color: #999">{{ formData.productName }}</span>
                </el-form-item> -->
                <el-form-item label="标签：" label-width="60px" v-if="selectTags.length > 0">
                    <div class="tag-list">
                        <el-tag v-for="(item,index) in selectTags" :key="index" type='success' closable @close="tagClose(index)">{{ item }}</el-tag>
                    </div>
                </el-form-item>
                <div class="btn-box">
                    <el-button type="primary" @click="getAdData" :loading="chatloading">获取广告语</el-button>
                </div>
            </el-form>
            <div class="content-box">
                <div class="content-text" v-for="(item, index) in content" :key="index">
                    <p v-show="item">{{ item }}</p>
                </div>
            </div>
        </div>
    </el-drawer>
</template>

<script>
import { getSaleDetails, createAd } from '@/api/audiences.js'
export default {
    data() {
        return {
            drawer: false,
            sale_id: '',
            tagInfo: [],
            selectTags: [],
            formData: {},
            loading: false,
            content: [],
            chatloading: false
        }
    },
    methods: {
        commandHandle(val) {
            let flag = true
            this.selectTags.forEach(item => {
                if (item === val) {
                    flag = false
                }
            })
            if (flag) this.selectTags.push(val)
        },
        tagClose(index) {
            this.selectTags.splice(index, 1)
        },
        getAdData() {
            this.chatloading = true
            let data = this.selectTags.join('')
            let request = {
                label: data,
                saleId: this.sale_id
            }
            createAd(request).then(res => {
                this.chatloading = false
                if (res.code === 0) {
                    this.content = res.data
                }
            }).catch(() => {
                this.chatloading = false
            })
        },
        // 获取 推荐 标签
        getSaleTags() {
            let request = {
                proCode: this.formData.productId,
                proName: this.formData.productName,
                goodsCode: this.sale_id,
                lineName: this.formData.countryName
            }
            // let request = {"proCode":517477,"proName":"X20121801U-木工雕刻刀钻头套装","goodsCode":1001151647,"lineName":"台湾"}
            let baseUrl = 'https://gads3.giikin.com/5000'
            this.$axios.post(`${baseUrl}/baseInfo`, request).then(result => {
                let statusCode = 'status Code'
                if (result.data[statusCode] === 1) {
                    let info = result.data.info
                    for (let key in info) {
                        let item = {
                            key: key,
                            values: info[key]
                        }
                        this.tagInfo.push(item)
                    }
                }
                this.loading = false
            }).catch((error) => {
                this.$message.error(error)
            })
        },
        show() {
            this.loading = true
            this.sale_id = this.$store.state.num.currentAddSaleId
            this.tagInfo = []
            this.content = []
            this.drawer = true
            getSaleDetails(this.sale_id).then(res => {
                if (res.code === 0) {
                    let data = res.data
                    this.formData.categoryFullName = data.categoryFullName
                    this.formData.productName = data.productName
                    this.formData.productId = data.productId
                    this.formData.countryName = data.countryName
                    this.getSaleTags()
                }
            })
        },
        hide() {
            this.drawer = false
        }
    }
}
</script>

<style lang="scss" scoped>
.sale-box {
    padding: 0 20px;
    .el-dropdown {
        margin-right: 10px;
    }
    .tag-list {
        .el-tag {
            margin-right: 10px;
        }
    }
    .el-form-item {
        margin-bottom: 0;
    }
    .btn-box {
        width: 100%;
        margin: 10px auto;
        .el-button {
            width: 100%;
        }
    }
    .content-box {
        p {
            font-size: 14px;
            color: #333;
            background: #f2f2f2;
            padding: 10px;
            border-radius: 4px;
            margin-bottom: 20px;
        }
    }
}
</style>