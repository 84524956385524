<template>
  <el-table
    ref="multipleTable"
    :data="tableData"
    tooltip-effect="dark"
    style="width: 100%"
    @selection-change="handleSelectionChange"
    height="500"
    @sort-change="handleSortChange"
    v-loading="loading"
    :default-sort="{ prop: 'useNum', order: 'descending' }"
    @row-click="handleClickRowClick"
  >
    <el-table-column type="selection" width="55"> </el-table-column>
    <el-table-column
      label="受众词"
      prop="targeting"
      width="200"
    ></el-table-column>
    <el-table-column
      label="翻译"
      prop="translation"
      width="150"
    >
      <template v-slot="{row}">
        <div>
          <el-link :underline="false" v-if="!row.translation" @click.stop="tranlateRow(row,$event)" type="primary">查看翻译</el-link>
          <span v-else> {{row.translation}}</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="useNum" sortable="custom" label="使用次数">
    </el-table-column>
    <el-table-column prop="productNum" sortable="custom" label="使用产品数">
    </el-table-column>
    <el-table-column prop="orderNum" sortable="custom" label="产生订单数">
    </el-table-column>
    <el-table-column prop="roi" sortable="custom" label="ROI">
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit("multiple", val);
    },
    handleClickRowClick(row,col,e){
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    closeSelection(item){
      if(item){
        this.$refs.multipleTable.toggleRowSelection(item);
      }
    },
    handleSortChange(e) {
      this.$emit("sort-change", e);
    },
    toggleSelection(rows) {
      this.clearSelection();
      this.$nextTick(() => {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        }
      });
    },
    clearSelection(){
      this.$refs.multipleTable.clearSelection();
    },
    tranlateRow(row,e){
      e.stopPropagation();
      this.$emit("translate-row",row)
    }
  },
};
</script>
<style lang="scss" scoped>
</style>