let id = 0;
export default [{
    name: "电子电器",
    category: "Video Software;;GPS & Navigation;;Wearable Technology;;Import & Export;;Computer Systems & Hardware;;Home Audio & Video;;Wireless Devices;;Computer Accessories;;Car Audio & Video;;Automotive Electronic Components;;Mobile Phones;;Mobile Phone Accessories",
    id: id++
}, {
    name: "服饰",
    category: "Prom Dresses;;Plus Size Clothing;;Sport Coats & Jackets;;Jewelry;;Tuxedos & Tuxedo Accessories;;Sweatshirts & Hoodies;;Bridal Wear;;Utility Clothing;;Fashion & Style;;Suits & Business Attire;;Women's Clothing;;Shirts, Tops & Blouses;;Dresses;;Costumes & Costume Rental;;Swimwear;;Underwear;;Sleepwear;;Skirts;;Apparel Accessories;;Men's Clothing;;Sporting Goods;;Pants, Jeans & Trousers;;Wigs & Wig Accessories;;Socks & Hosiery;;Children's Clothing;;Outerwear;;Tanning & Sun Care;;Shorts;;Pullover Sweaters & Cardigans;;Uniforms;;Vests;;Petite Clothing;;Yoga & Pilates;;Sports & Fitness Apparel",
    id: id++
}, {
    name: "包",
    category: "Jewelry;;Fashion & Style",
    id: id++
}, {
    name: "鞋",
    category: "Footwear;;Fashion & Style;;Sporting Goods",
    id: id++
}, {
    name: "居家百货",
    category: "Kitchen & Bathroom Cabinets;;Yard, Garden & Patio;;Home Automation;;Home Furniture;;Home Office Furniture;;Home Improvement & Maintenance;;Household Supplies;;Home Appliances;;Home Heating & Cooling;;Kitchen & Dining;;Residential Cleaning;;Home Decor & Interior Decorating;;Home Safety & Security;;Lights & Lighting;;Bedding & Linens;;Portable Media Devices;;Bathroom;;Perfumes & Fragrances;;Water Filters;;Home Laundry;;Hygiene & Toiletries",
    id: id++
}, {
    name: "美容个护",
    category: "Nail Care;;Hair Care;;Shaving & Grooming;;Make-Up & Cosmetics;;Lipsticks & Lip Glosses;;Oral Care;;Skin Care;;Eye Make-Up;;Anti-Aging;;Lip Care;;Blush;;Face Powder;;Concealer",
    id: id++
}, {
    name: "母婴玩具",
    category: "Maternity Wear",
    id: id++
}]