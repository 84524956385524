<template>
  <el-table
    ref="multipleTable"
    :data="tableData"
    tooltip-effect="dark"
    style="width: 100%"
    @selection-change="handleSelectionChange"
    height="500"
    @row-click="handleClickRowClick"
  >
    <el-table-column type="selection" width="55"> </el-table-column>
    <el-table-column
      label="受众词"
      prop="name"
      width="200"
    ></el-table-column>
    <el-table-column prop="audience_size" label="规模">
      <template v-slot="{row}">
        <span>{{ formatData(row.audience_size) }}</span>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit("multiple", val);
    },
    handleClickRowClick(row,col,e){
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    formatData(val) {
      return parseFloat(val/10000).toFixed(1) + '万'
    }
  },
};
</script>